import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_patch_hash=hqbdy6ii5uywzhocr4ax2mrbey_@opentelemetry+api@1.8.0_@playwright+test@_fzmc3bbexcm746jfkn3mg3ipim/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_patch_hash=hqbdy6ii5uywzhocr4ax2mrbey_@opentelemetry+api@1.8.0_@playwright+test@_fzmc3bbexcm746jfkn3mg3ipim/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_patch_hash=hqbdy6ii5uywzhocr4ax2mrbey_@opentelemetry+api@1.8.0_@playwright+test@_fzmc3bbexcm746jfkn3mg3ipim/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_patch_hash=hqbdy6ii5uywzhocr4ax2mrbey_@opentelemetry+api@1.8.0_@playwright+test@_fzmc3bbexcm746jfkn3mg3ipim/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_patch_hash=hqbdy6ii5uywzhocr4ax2mrbey_@opentelemetry+api@1.8.0_@playwright+test@_fzmc3bbexcm746jfkn3mg3ipim/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_patch_hash=hqbdy6ii5uywzhocr4ax2mrbey_@opentelemetry+api@1.8.0_@playwright+test@_fzmc3bbexcm746jfkn3mg3ipim/node_modules/next/dist/client/components/render-from-template-context.js");
