'use client';

import { PropsWithChildren, useRef } from 'react';
import type { LinkProps } from 'next/link';
import { gtmService } from '@/util/gtm-service';

export function HeaderTextBannersLink({ href, children, ...props }: PropsWithChildren<LinkProps>) {
  const ref = useRef<HTMLAnchorElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const hrefString = href.toString();

  return (
    <a
      ref={ref}
      href={hrefString}
      {...props}
      onClick={() => {
        gtmService.recommended.selectContent('cta', 'front_page_floater_banner', {
          link_text: ref.current?.innerText ?? '',
          link_url: { link_type: 'Web', url: hrefString },
        });
      }}
    >
      {children}
    </a>
  );
}
