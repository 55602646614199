'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { gtmService } from '@/util/gtm-service';
import { useSession } from '@/hooks/useSession';

export function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { currentUser } = useSession();

  useEffect(() => {
    if (currentUser.data === undefined) {
      return;
    }

    gtmService.sendUserData(currentUser.data);
  }, [pathname, searchParams, currentUser]);

  return null;
}
